<template>
  <div class="hello ">
   <div class="p-3 px-10 bg-green-500 text-white"><h1 class="text-3xl font-bold">Bo-blog</h1></div>
   <div class="p-10">
    <div class="text-lg text-gray-500">
        岁月是一把利刃<br>
        划过记忆的空间 <br> 留下了时代的沟壑<br>
        沉淀在过往的<br>
        是我们逐渐褪色的字迹<br>
    </div>
    <div class="mt-20"><h3 class="text-gray-600 text-base font-bold">存档下载*</h3></div>
    <div class="mt-4 w-1/2 text-base">
      <div class="border-b-2 border-green-300 text-gray-400"><a href="https://github.com/bo-blog/bw/archive/master.zip">Bo-blog Wind v1.7.0</a></div>
      <div class="border-b-2 border-green-300 text-gray-400"><a href="http://down.chinaz.com/soft/14624.htm" target="_blank">Bo-blog v2.1</a> </div>
    </div>
    <div class="mt-10"><div class="text-xs text-gray-400">* 仅供存档和调试，请勿用于生产环境。</div></div>
    <div class="mt-20"><h3 class="text-gray-500 text-base italic">Stay tuned.</h3></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  }
}
</script>
